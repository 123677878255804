<template>
    <div>
        <div v-show="!success && !error">
            <Survey ref="survey" :survey="survey" />
        </div>

        <div v-show="success">
            <div class="mx-auto mt-8 max-w-2xl bg-orange-100 p-8">
                <p class="text-center text-lg">
                    Vielen Dank für Ihr Engagement. Sie werden automatisch
                    weitergeleitet. Sollten Sie nicht weitergeleitet werden
                    klicken Sie bitte hier:
                    <a
                        :href="redirect"
                        class="mt-8 inline-block rounded bg-orange-500 px-6 py-4 text-white"
                        >Zum Ergebnis</a
                    >
                </p>
            </div>
        </div>

        <div v-show="error">
            <div
                class="mx-auto mt-8 max-w-2xl bg-orange-100 p-8 text-center text-lg"
            >
                <div v-if="statusCode == 404">
                    Es konnte keine zugeordnete Umfrage-Runde gefunden werden
                </div>
                <div v-if="statusCode == 405">
                    Für diese Umfrage wurde bereits abgestimmt
                </div>
            </div>
        </div>
        <div
            v-show="isWorking"
            class="z-90 fixed inset-0 flex flex-wrap items-center justify-center bg-orange-100 bg-opacity-90"
        >
            <div class="flex flex-col items-center text-center">
                <svg
                    class="mx-auto -ml-1 mr-3 h-48 w-48 animate-spin text-orange-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <//div></path>
                </svg>
                <div class="mt-8 text-3xl font-bold text-orange-500">
                    Ihre Daten werden verarbeitet
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* ------------- SURVEY --------- */
import 'survey-core/modern.min.css'
import {
    StylesManager,
    Model,
    surveyLocalization,
    Serializer,
} from 'survey-core'

import { Survey } from 'survey-vue-ui'
import de from '@var/survey_de_locale.json'

Serializer.addProperty('question', 'helpText:text')
Serializer.addProperty('page', 'helpText:text')

let defaultThemeColors = StylesManager.ThemeColors['modern']
defaultThemeColors['$main-color'] = '#EC7D27'
defaultThemeColors['$main-hover-color'] = '#F9A64A'
defaultThemeColors['$header-color'] = '#EC7D27'
StylesManager.applyTheme('modern')
surveyLocalization.locales['de'] = de
surveyLocalization.defaultLocale = 'de'
/* -------------------------------- */
/* import surveyJson from '@var/teens-questions.json' */
import schoolQuestions from '@var/school-questions.json'
import schoolZusatzfragen from '@var/school-zusatzfragen.json'

import kitaQuestions from '@var/kita-questions.json'
import kitaZusatzfragen from '@var/kita-zusatzfragen.json'

import calculator from '@js/calculator'

import Modal from '@vue/components/Modal.vue'
/* -------------------------------- */

export default {
    name: 'FrageBogen',
    components: {
        Survey,
    },
    props: {
        identifier: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        surveyNo: {
            type: Number,
            required: true,
        },
    },

    data() {
        let survey = null
        if (this.category == 'Kita') {
            // ab runde #2 werden zusatzfragen angezeigt
            if (this.surveyNo > 1) {
                kitaQuestions.pages.splice(
                    kitaQuestions.pages.length - 1,
                    0,
                    kitaZusatzfragen
                )
            }
            survey = new Model(kitaQuestions)
        }
        if (this.category == 'Schule') {
            // ab runde #2 werden zusatzfragen angezeigt
            if (this.surveyNo > 1) {
                schoolQuestions.pages.splice(
                    schoolQuestions.pages.length - 1,
                    0,
                    schoolZusatzfragen
                )
            }
            survey = new Model(schoolQuestions)
        }
        survey.focusFirstQuestionAutomatic = false
        window.survey = survey

        let $this = this

        survey.onComplete.add(this.calculateResults)

        survey.onAfterRenderQuestion.add(function (survey, options) {
            // Do nothing if a page contains no description to show in a modal popup
            if (!options.question.helpText) return

            //Create a 'More Info' button to invoke a modal popup
            var btn = document.createElement('button')
            btn.type = 'button'
            btn.className = 'text-orange-500'
            btn.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 -mb-1 fill-current text-orange-200 hover:text-orange-500" viewBox="0 0 20 20" fill="currentColor"> <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" /> </svg>'
            btn.onclick = function () {
                $this.showDescription(options.question)
            }
            // Insert the created 'More Info' button into the rendered page's header
            var header = options.htmlElement.querySelector(
                '.sv-vue-title-additional-div .sv-string-viewer'
            )
            var span = document.createElement('span')
            span.innerHTML = '  '
            header.appendChild(span)
            header.appendChild(btn)
        })

        return {
            survey,
            isWorking: false,
            success: false,
            error: false,
            statusCode: 405,
            redirect: null,
        }
    },
    mounted() {},
    methods: {
        showDescription(element) {
            this.$modal.show(
                Modal,
                { text: element.helpText },
                { draggable: false, height: 'auto' }
            )
        },
        calculateResults(sender, options) {
            let result = calculator(
                sender,
                options,
                this.identifier,
                this.category
            )
            console.log(result)
            this.saveResult(result)
        },
        async saveResult(result) {
            this.isWorking = true
            const response = await fetch('/fragebogen/' + this.identifier, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                body: JSON.stringify(result),
            })

            let status = response.status
            let data = await response.json()

            if (status == 201) {
                this.success = true
                this.redirect = data.redirect
                this.isWorking = false
                window.location.replace(data.redirect)
            } else {
                this.error = true
                this.isWorking = false
                this.statusCode = status
            }
        },
    },
}
</script>
<style lang="css">
.sv-question__title {
    border-radius: 12px;
    padding: 10px;
}
.sv-btn {
    @apply !rounded-[12px] !bg-wildberry-light-500 px-4 py-2 text-white ring-1 ring-transparent ring-offset-2;
}
.sv-btn:hover {
    @apply hover:ring-wildberry-light-500;
}
.sv-root-modern .sv-radio--checked .sv-radio__svg {
    @apply !border-wildberry-light-500 !fill-current !text-wildberry-light-500;
}

.sv-root-modern .sv-progress__bar {
    @apply !bg-wildberry-light-500 !fill-current;
}

.sv-page.sv-body__page {
    margin-top: 0rem;
}
.sv-matrix {
    border-radius: 12px;
}
.sv-table.sv-matrix-root {
    tr:nth-child(even) {
        background-color: rgba(159, 159, 159, 0.2);
    }
}
.sv-matrix-root .sv-matrix__row--error:nth-child(odd) {
    background-color: rgba(213, 41, 1, 0.2) !important;
}
.sv-matrix-root .sv-matrix__row--error:nth-child(even) {
    background-color: rgba(213, 41, 1, 0.3) !important;
}
.sv-container-modern__title {
    padding-top: 2rem;
}
.sv-table__cell.sv-matrix__cell {
    min-width: 3rem;
}
</style>
