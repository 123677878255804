// Math
import { sum } from 'mathjs'
import { filter } from 'lodash'

function calculateSubscale(item) {
    console.log(item)
    item.sum = filter(item.sum, (o) => o !== null)
    let summe = sum(item.sum)
    let valueCount = item.sum.length

    if (valueCount > 0) {
        return summe / valueCount
    }

    return null
}
function calculateProcentualValue(item) {
    console.log(item)
    if (item.maxValue == undefined) {
        item.maxValue = false
    }

    let totalQuestions = item.sum.length

    // filter out unanswered questions
    if (item.maxValue) {
        item.sum = filter(item.sum, (o) => o <= item.maxValue)
    }

    // this is the same as totalQuestions if no items were filtered
    let totalAnsweredQuestions = item.sum.length

    let numerator = sum(item.sum)

    // change this to totalQuestions|totalAnsweredQuestions in case
    let denominator = totalQuestions * item.maxValue // Nenner

    if (denominator > 0) return (numerator / denominator) * 100
    else return null
}
export default function calculator(sender, options, identifier, category) {
    let r = sender.data

    let ST_items = {
        name: 'ST',
        sum: [r.ST.ST1, r.ST.ST2, r.ST.ST3, r.ST.ST4, r.ST.ST5],
        maxValue: 4,
    }

    let APA_items = {
        name: 'APA',
        sum: [r.AP.A1, r.AP.A2],
        maxValue: 4,
    }

    let APP_items = {
        name: 'APP',
        sum: [r.AP.P2, r.AP.P3, r.AP.P4],
        maxValue: 4,
    }

    let UEU_items = {
        name: 'UEU',
        sum: [r.UE.U1, r.UE.U2, r.UE.U3],
        maxValue: 4,
    }
    let UEE_items = {
        name: 'UEE',
        sum: [r.UE.E1, r.UE.E2, r.UE.E3],
        maxValue: 4,
    }

    let SE_items = {
        name: 'SE',
        sum: [r.SE.SE1, r.SE.SE2, r.SE.SE3],
        maxValue: 4,
    }

    let BW_items = {
        name: 'BW',
        sum: [r.TU.B1, r.TS.B2, r.HB.B3],
        maxValue: 3,
    }

    let ER_items = {
        name: 'ER',
        sum: [r.TU.ER1, r.TS.ER2, r.TS.ER3, r.HB.ER4],
        maxValue: 3,
    }

    let PS_items = {
        name: 'PS',
        sum: [r.TU.PS1, r.TU.PS2, r.TS.PS3, r.TS.PS4, r.TS.PS5, r.HB.PS6],
        maxValue: 3,
    }

    let SV_items = {
        name: 'SV',
        sum: [r.TU.S1, r.TS.S2, r.HB.S3],
        maxValue: 3,
    }

    let GKS_items = {
        name: 'GKS',
        sum: [
            r.GKS.GKS1,
            r.GKS.GKS2,
            r.GKS.GKS3,
            r.GKS.GKS4,
            r.GKS.GKS5,
            r.GKS.GKS6,
            r.GKS.GKS7,
            r.GKS.GKS8,
        ],
        maxValue: 3,
    }

    let ST = calculateProcentualValue(ST_items)
    let APA = calculateProcentualValue(APA_items)
    let APP = calculateProcentualValue(APP_items)
    let UEU = calculateProcentualValue(UEU_items)
    let UEE = calculateProcentualValue(UEE_items)
    let SE = calculateProcentualValue(SE_items)
    let BW = calculateProcentualValue(BW_items)
    let ER = calculateProcentualValue(ER_items)
    let PS = calculateProcentualValue(PS_items)
    let SV = calculateProcentualValue(SV_items)

    let GZS = calculateSubscale({
        name: 'GZS',
        sum: [ST, APA, APP, UEU, UEE, SE],
    })

    let HF = calculateSubscale({
        name: 'HF',
        sum: [BW, ER, PS, SV],
    })

    let GKS = calculateProcentualValue(GKS_items)

    let HMS = calculateSubscale({
        name: 'HMS',
        sum: [GZS, HF, GKS],
    })

    let VE = calculateSubscale({
        name: 'VE',
        sum: [ST, HF],
    })

    let result = {
        identifier: identifier,
        category: category,
        rolle: r.rolle,
        rollenName: r.rollenName,
        existingConcept: r.existingConcept,
        betreuung: r.betreuung,
        participatingInOtherProjects: r.participatingInOtherProjects,
        projectName: r.projectName,
        ST: ST,
        APA: APA,
        APP: APP,
        UEU: UEU,
        UEE: UEE,
        SE: SE,
        BW: BW,
        ER: ER,
        PS: PS,
        SV: SV,

        GZS: GZS,
        HF: HF,
        GKS: GKS,
        HMS: HMS,
        VE: VE,

        rawData: r,
    }

    return result
}
