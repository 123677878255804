import Chart from 'chart.js/auto'

document.addEventListener('DOMContentLoaded', function () {
    let chart1 = document.querySelector('#chart1')
    let chart2 = document.querySelector('#chart2')

    // =============================================================
    // =============================================================

    if (chart1) {
        var chart1Data = JSON.parse(chart1.dataset.dataset)
        const data1 = {
            labels: [
                'Bewegung',
                'Ernährung',
                'psychische Gesundheit & Fitness',
                'Suchtprävention',
            ],
            datasets: [
                {
                    label: 'Aktuell',
                    data: chart1Data,
                    backgroundColor: [
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                    ],
                    borderColor: 'rgb(250, 183, 108)', // Linien
                    pointRadius: 20,
                    pointBackgroundColor: 'rgb(236, 125, 39)', // Punkte
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(236, 125, 39)', // Punkte beim Hovern
                },
            ],
        }
        const config1 = {
            type: 'bar',
            data: data1,
            options: {
                indexAxis: 'y',
                scales: {
                    x: {
                        title: 'test',
                        min: 0,
                        max: 100,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value + '%'
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                        text: 'Custom Chart Title',
                        labels: {
                            padding: 20,
                            font: {
                                size: 12,
                            },
                            // color: 'rgb(255, 99, 132)',
                        },
                    },
                    title: {
                        display: true,
                        text: 'Handlungsfelder',
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (context) {
                                let label = ' '
                                if (context.parsed.x !== null) {
                                    label += new Intl.NumberFormat('de-DE', {
                                        style: 'percent',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(context.parsed.x / 100)
                                }
                                return label
                            },
                        },
                    },
                },
                onClick: (e) => {},
                elements: {
                    line: {
                        borderWidth: 3,
                    },
                },
            },
        }

        const Chart1 = new Chart(document.getElementById('chart1'), config1)
    }
    // ==================================================================

    if (chart2) {
        var chart2Data = JSON.parse(chart2.dataset.dataset)
        const data2 = {
            labels: [
                'Strukturen',
                'Analyse',
                'Planung',
                'Umsetzung',
                'Evaluation',
                'Sensibilisierung',
            ],
            datasets: [
                {
                    label: 'Aktuell',
                    data: chart2Data,
                    backgroundColor: [
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                        'rgb(236, 125, 39, 0.6)',
                    ],
                    borderColor: 'rgb(250, 183, 108)', // Linien
                    pointRadius: 20,
                    pointBackgroundColor: 'rgb(236, 125, 39)', // Punkte
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(236, 125, 39)', // Punkte beim Hovern
                },
            ],
        }

        const config2 = {
            type: 'bar',
            data: data2,
            options: {
                indexAxis: 'y',
                scales: {
                    x: {
                        title: 'test',
                        min: 0,
                        max: 100,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value + '%'
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                        text: 'Custom Chart Title',
                        labels: {
                            padding: 20,
                            font: {
                                size: 12,
                            },
                            // color: 'rgb(255, 99, 132)',
                        },
                    },
                    title: {
                        display: true,
                        text: 'Gesundheitsförderungszyklus',
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (context) {
                                let label = ' '
                                if (context.parsed.x !== null) {
                                    label += new Intl.NumberFormat('de-DE', {
                                        style: 'percent',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(context.parsed.x / 100)
                                }
                                return label
                            },
                        },
                    },
                },
                onClick: (e) => {},
                elements: {
                    line: {
                        borderWidth: 3,
                    },
                },
            },
        }

        const Chart2 = new Chart(document.getElementById('chart2'), config2)
    }
})
