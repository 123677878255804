import Vue from 'vue'
import Fragebogen from '@vue/components/Fragebogen.vue'

Vue.config.productionTip = false
import VModal from 'vue-js-modal'
Vue.use(VModal)

new Vue({
    components: { Fragebogen },
}).$mount('#app')
